import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter'
import { Button } from '@/components/ui/button'
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import FaqPage from '@/pages/home/intro/FaqPage'
import PricingPage from '@/pages/home/intro/PricingPage'
import ContactUsPage from '@/pages/home/intro/ContactUsPage'


export default function Component() {

  const location = useLocation();

  const faqsRef = useRef<HTMLDivElement>(null)
  const pricingRef = useRef<HTMLDivElement>(null)
  const contactUsRef = useRef<HTMLDivElement>(null)


  useEffect(() => {
    // 页面位置定位
    console.log('location.hash', location.hash)
    const scrollToSection = () => {
      if (location.hash === '#faqs' && faqsRef.current) {
        faqsRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
      if (location.hash === '#pricing' && pricingRef.current) {
        pricingRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
      if (location.hash === '#contact-us' && contactUsRef.current) {
        contactUsRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }
    scrollToSection();

  }, [location.hash]);

  return (
    <div className="flex flex-col min-h-screen justify-between">

      <main>
        <div className='bg-cover bg-center' style={{ backgroundImage: "url('/image/landing_topbg.jpg')" }}>
          <BoxPageHeader defaultBgClassName='bg-transparent' scrollBgClassName='bg-white' />

          <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 text-black text-center">
            <div className="container px-4 md:px-6">
              <div className="flex flex-col items-center space-y-4">
                <h1 className="select-none text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl bg-gradient-to-r from-[#6366F1] to-[#EC4899] bg-clip-text text-transparent">
                  Easy File Collection with fileinbox.online
                </h1>

                <p className="max-w-lg mx-auto">We provide you with the most outstanding services in the industry</p>
                <Link to='/signin'>
                  <Button className="bg-green-500 text-white hover:bg-green-600">Get Started</Button>
                </Link>
              </div>
            </div>
          </section>

          <section className="py-20 px-12 bg-gray-50 container">
            <div className="grid grid-cols-2 gap-8">
              <div className="flex flex-col items-center justify-center">
                <h2 className="text-3xl font-bold mb-6">Easy File Collection with fileinbox.online</h2>
                <p className="text-lg mb-4 mr-auto">
                  Submitters do not need to log in. Files are automatically renamed after submitted. Supports collection of any file format
                </p>
                <p className="text-lg mb-4 mr-auto">
                  Effortless and efficient, shows your professionalism and efficiency
                </p>

                <Button className="bg-blue-500 text-white text-lg" size={"lg"}>Start for free</Button>
              </div>
              <div>
                <img
                  alt="Product info"
                  className="mb-4 mx-auto"
                  height="300"
                  src="/placeholder.svg"
                  style={{
                    aspectRatio: "500/300",
                    objectFit: "cover",
                  }}
                  width="500"
                />
              </div>
            </div>
          </section>
        </div>




        <section className="py-20 px-12 container">
          <div className="flex flex-col items-center text-center space-y-4">
            <p className="text-3xl font-bold">What challenges have you encountered when receiving files?</p>
          </div>
        </section>
        <section className="py-20 px-12 bg-gray-100 container">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-6">Manage finances easily with clear income and expenses</h2>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <img
                  alt="Financial Management"
                  className="mb-4 mx-auto"
                  height="300"
                  src="/placeholder.svg"
                  style={{
                    aspectRatio: "500/300",
                    objectFit: "cover",
                  }}
                  width="500"
                />
                <p className="text-lg mb-4">
                  Financial management is no longer a tedious task. Our tool allows you to easily track and manage your
                  finances, whether it's invoices or budgets. With our solution, you can maintain complete control of your
                  finances, ensuring the accuracy and security of financial data.
                </p>
                <Button className="bg-blue-500 text-white">Learn more</Button>
              </div>
              <div>
                <img
                  alt="Invoice Tracking"
                  className="mb-4 mx-auto"
                  height="300"
                  src="/placeholder.svg"
                  style={{
                    aspectRatio: "500/300",
                    objectFit: "cover",
                  }}
                  width="500"
                />
                <p className="text-lg mb-4">
                  Our invoice tracking system makes your life easier. You can quickly view all unpaid, paid, and overdue
                  invoices. Through our system, you can ensure that all invoices are processed in a timely manner, thereby
                  improving cash flow.
                </p>
                <Button className="bg-blue-500 text-white">Learn more</Button>
              </div>
            </div>
          </div>
        </section>



        <section className="py-20 px-12 container">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-6">Easy to use, making document management easier</h2>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <img
                  alt="Document Management"
                  className="mb-4 mx-auto"
                  height="300"
                  src="/placeholder.svg"
                  style={{
                    aspectRatio: "500/300",
                    objectFit: "cover",
                  }}
                  width="500"
                />
                <p className="text-lg mb-4">
                  Document management is simple. Our solution allows you to easily store, share and edit all types of
                  files, without worrying about incompatible formats or data loss.
                </p>
                <Button className="bg-blue-500 text-white">Learn more</Button>
              </div>
              <div>
                <img
                  alt="Collaboration"
                  className="mb-4 mx-auto"
                  height="300"
                  src="/placeholder.svg"
                  style={{
                    aspectRatio: "500/300",
                    objectFit: "cover",
                  }}
                  width="500"
                />
                <p className="text-lg mb-4">
                  Collaboration has never been so easy. Our tools support your team to collaborate seamlessly, whether it
                  is co-editing documents or video conferencing, our solution can meet your needs.
                </p>
                <Button className="bg-blue-500 text-white">Learn more</Button>
              </div>
            </div>
          </div>
        </section>
        <section className="py-20 px-12 bg-gray-100 container">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-6">Testimonials</h2>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <blockquote className="text-lg mb-4">
                  "This tool has been a game changer for our team. It's easy to use and has all the features we need to
                  manage our projects effectively."
                </blockquote>
                <p>
                  <strong>- Jane Doe, Project Manager</strong>
                </p>
              </div>
              <div>
                <blockquote className="text-lg mb-4">
                  "I love how I can manage all my finances in one place. It's saved me so much time and made my job a lot
                  easier."
                </blockquote>
                <p>
                  <strong>- John Smith, Finance Manager</strong>
                </p>
              </div>
            </div>
          </div>
        </section>



        <div ref={faqsRef}>
          <FaqPage />
        </div>

        <div ref={pricingRef}>
          <PricingPage />
        </div>

        <div ref={contactUsRef}>
          <ContactUsPage />
        </div>

      </main>
      <BoxPageFooter />
    </div>
  )
}