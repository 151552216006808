import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter'
import { Button } from "@/components/ui/button"
import { CardTitle, CardDescription, CardHeader, CardContent, Card } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useEffect, useRef, useState } from 'react';
import { Checkbox } from "@/components/ui/checkbox"
import CryptoJS from 'crypto-js'
import BoxRequest from '@/common/util/BoxRequest';
import BoxApi from '@/common/BoxApi';
import { TokenModel } from '@/common/model/TokenModel';
import { useToast } from "@/components/ui/use-toast"
import { BoxRespModel } from '@/common/model/BoxRespModel';
import JSEncrypt from 'jsencrypt';


export default function Component() {
  const navigate = useNavigate();
  const { toast } = useToast()
  const rsaEncryptorRef = useRef(new JSEncrypt());

  // 获取默认type=login / signup
  const queryParams = new URLSearchParams(useLocation().search);
  const signinType: string = queryParams.get('type') || 'login';

  const [tabActive, setTabActive] = useState<string>(signinType); // ['login', 'signup']
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [emailCheckResult, setEmailCheckResult] = useState(' ');
  const [passwordCheckResult, setPasswordCheckResult] = useState(' ');
  const [confirmPasswordCheckResult, setConfirmPasswordCheckResult] = useState(' ');
  const [acceptSignUpPolicy, setAcceptSignUpPolicy] = useState(false);
  const [canLogin, setCanLogin] = useState(false);
  const [canSignUp, setCanSignUp] = useState(false);

  

  useEffect(() => {
    // 初始化读取rsa公钥
    BoxRequest.getServerPublicKey().then((pubKey) => {
      if (pubKey) {
        rsaEncryptorRef.current.setPublicKey(pubKey);
      }
    });
  }, []);


  //检查是否已经登录
  if (BoxRequest.getLoginFlag()) {
    console.log('already login, redirect to home page');
    navigate('/home');
  }


  // 检查邮箱函数
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    checkEmail(event.target.value);
  }
  const checkEmail = (emailStr: string) => {
    if (emailStr === '') {
      setEmailCheckResult('⚠️ email is required');
      return false;
    }
    //regex check email
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(emailStr)) {
      setEmailCheckResult('❌ email format is incorrect');
      return false;
    }
    setEmailCheckResult('');
    return true;
  }

  // 检查密码格式
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    checkPassword(event.target.value);
  }
  const checkPassword = (passwordStr: string) => {
    if (passwordStr.length < 8) {
      setPasswordCheckResult('❌ password must be at least 8 characters');
      return false;
    }
    if (passwordStr.length > 20) {
      setPasswordCheckResult('❌ password must be less than 20 characters');
      return false;
    }
    setPasswordCheckResult('');
    return true;
  }

  useEffect(() => {
    setCanLogin(emailCheckResult === '' && passwordCheckResult === '');
  }, [email, password, emailCheckResult, passwordCheckResult]);

  // 检查确认密码
  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
    checkConfirmPassword(event.target.value);
  }
  const checkConfirmPassword = (confirmPasswordStr: string) => {
    if (confirmPasswordStr !== password) {
      setConfirmPasswordCheckResult('❌ password does not match');
      return false;
    }
    setConfirmPasswordCheckResult('');
    return true;
  }

  useEffect(() => {
    setCanSignUp(emailCheckResult === '' && passwordCheckResult === '' && confirmPasswordCheckResult === '' && acceptSignUpPolicy);
  }, [email, password, confirmPassword, acceptSignUpPolicy, emailCheckResult, passwordCheckResult, confirmPasswordCheckResult]);

  const handleCheckedPolicy = (checked: boolean) => {
    setAcceptSignUpPolicy(checked);
  }

  // 登录逻辑
  const login = () => {
    if (!canLogin) {
      toast({
        title: "Login Fail",
        description: 'params check fail.',
      })
      return;
    }
    let pwdHash: string = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
    let encryptedPwdHash:string = rsaEncryptorRef.current.encrypt(pwdHash).toString()

    console.info('pubKey:', rsaEncryptorRef.current.getPublicKeyB64());
    BoxRequest.post(BoxApi.login, {
      email: email,
      pwdHash: encryptedPwdHash
    }).then((resp: BoxRespModel<TokenModel>) => {
      if (!resp) {
        toast({
          title: "Login Fail",
          description: 'seems network error',
        })
        return;
      }

      if (resp.success && resp.data?.isLogin) {
        let tokenDto: TokenModel = resp.data;
        //save login token to localStorage
        localStorage.setItem('tokenName', tokenDto.tokenName);
        localStorage.setItem('tokenValue', tokenDto.tokenValue);
        localStorage.setItem('userId', tokenDto.loginId);
        let timeoutAt: number = Date.now() + tokenDto.tokenTimeout * 1000;
        localStorage.setItem('tokenTimeoutAt', String(timeoutAt));
        toast({
          title: "Login Success",
          description: '',
        })
        navigate('/home');
      } else {
        toast({
          title: "Login Fail",
          description: resp.displayMsg || 'unknown error',
        })
      }
    }).catch((err) => {
      toast({
        variant: "destructive",
        title: "Login Fail",
        description: err.message,
      })
    })
  }

  // 注册逻辑
  const signup = () => {
    if (!canSignUp) {
      toast({
        title: "Sign Up Fail",
        description: 'params check fail.',
      })
      return;
    }
    let pwdHash: string = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
    let encryptedPwdHash:string = rsaEncryptorRef.current.encrypt(pwdHash) as string;
    let encryptedPwdOrigin:string = rsaEncryptorRef.current.encrypt(password) as string;

    BoxRequest.post(BoxApi.register, {
      email: email,
      pwdOrigin: encryptedPwdOrigin,
      pwdHash: encryptedPwdHash
    }).then((resp) => {
      if (!resp) {
        toast({
          title: "Sign Up Fail",
          description: 'seems network error',
        })
        return;
      }
      let tokenDto: TokenModel = resp.data;
      if (resp.success && tokenDto.isLogin) {
        //save login token to localStorage
        localStorage.setItem('tokenName', tokenDto.tokenName);
        localStorage.setItem('tokenValue', tokenDto.tokenValue);
        localStorage.setItem('userId', tokenDto.loginId);
        let timeoutAt: number = Date.now() + tokenDto.tokenTimeout * 1000;
        localStorage.setItem('tokenTimeoutAt', String(timeoutAt));
        toast({
          title: "Sign Up Success",
          description: '',
        })
        navigate('/home');
      } else {
        toast({
          title: "Sign Up Fail",
          description: 'unknown error',
        })
      }
    }).catch((err) => {
      toast({
        variant: "destructive",
        title: "Sign Up Fail",
        description: err.message,
      })
    })
  }

  return (
    <>
      <BoxPageHeader />

      <main className="flex flex-col items-center h-3/5 max-h-max bg-gray-200 py-12 px-4 sm:px-6 lg:px-8">
        <div>
          <Tabs defaultValue='login' className="w-[400px]" value={tabActive} onValueChange={(newVal) => { setTabActive(newVal) }}>
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="login">Log In</TabsTrigger>
              <TabsTrigger value="signup">Sign Up</TabsTrigger>
            </TabsList>
            <TabsContent value="login">
              <Card className="mx-auto">
                <CardHeader className="space-y-1">
                  <div className="flex items-center justify-center">
                    {/* <img className="w-10 h-10" src='/placeholder.svg' /> */}
                    <CardTitle className="text-2xl font-bold ml-2">File Inbox</CardTitle>
                  </div>
                  <CardDescription>Log in to your account</CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="email">Email</Label>
                      <Input className="w-full" id="email" placeholder="m@example.com" required type="email"
                        value={email}
                        onChange={handleEmailChange} />
                    </div>
                    <div className="space-y-2">
                      <p className="text-gray-400">{emailCheckResult}</p>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center">
                        <Label htmlFor="password">Password</Label>
                        <Link className="ml-auto inline-block text-sm underline" to="#">
                          Forgot your password?
                        </Link>
                      </div>
                      <Input className="w-full" id="password" required type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        onKeyDown={(event) => {
                          if (canLogin && event.key === 'Enter') {
                            login();
                          }
                        }} />
                    </div>
                    <div className="space-y-2">
                      <p className="text-gray-400">{passwordCheckResult}</p>
                    </div>
                    <Button className="w-full" disabled={!canLogin} type="button" onClick={login}>
                      Log In
                    </Button>
                    <div className="mt-4 text-center text-sm">
                      Don't have an account?
                      <Link className="text-sm text-blue-500 hover:text-blue-400 ml-2 underline" to="#" onClick={(event) => {
                        event.preventDefault();
                        setTabActive("signup");
                      }}>
                        Sign Up
                      </Link>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
            <TabsContent value="signup">
              <Card className="mx-auto">
                <CardHeader className="space-y-1">
                  <div className="flex items-center justify-center">
                    {/* <img className="w-10 h-10" src='/placeholder.svg' /> */}
                    <CardTitle className="text-2xl font-bold ml-2">File Inbox</CardTitle>
                  </div>
                  <CardDescription>Sign up for a new account</CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="new_email">Email</Label>
                      <Input className="w-full" id="new_email" placeholder="m@example.com" required type="email" value={email} onChange={handleEmailChange} />
                    </div>
                    <div className="space-y-2">
                      <p className="text-gray-400">{emailCheckResult}</p>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center">
                        <Label htmlFor="new_password">Password</Label>
                      </div>
                      <Input className="w-full" id="new_password" required type="password" value={password} onChange={handlePasswordChange} />
                    </div>
                    <div className="space-y-2">
                      <p className="text-gray-400">{passwordCheckResult}</p>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center">
                        <Label htmlFor="confirm_password">Confirm Password</Label>
                      </div>
                      <Input className="w-full" id="confirm_password" required type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                    </div>
                    <div className="space-y-2">
                      <p className="text-gray-400">{confirmPasswordCheckResult}</p>
                    </div>
                    <div className="items-top flex space-x-2">
                      <Checkbox id="terms1" checked={acceptSignUpPolicy} onCheckedChange={handleCheckedPolicy} />
                      <div className="grid gap-1.5 leading-none">
                        <label
                          htmlFor="terms1"
                          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          Accept <Link to="/terms-of-service" className='underline text-blue-500'>Terms</Link> and <Link to='/privacy-policy' className='underline text-blue-500'>Privacy Policy</Link>
                        </label>
                      </div>
                    </div>
                    <Button className="w-full" disabled={!canSignUp} type="button" onClick={signup}>
                      Sign Up
                    </Button>
                    <div className="mt-4 text-center text-sm">
                      Already have an account?
                      <Link className="text-sm text-blue-500 hover:text-blue-400 ml-2 underline" to="#" onClick={(event) => {
                        event.preventDefault();
                        setTabActive('login');
                      }}>
                        Log In
                      </Link>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </div>
      </main>

      <BoxPageFooter />
    </>
  )
}