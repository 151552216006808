import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter'
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
// import { Checkbox } from "@/components/ui/checkbox"
import { useState } from 'react'
import { useToast } from '@/components/ui/use-toast'
import BoxApi from '@/common/BoxApi'
import BoxRequest from '@/common/util/BoxRequest'
import { BoxRespModel } from '@/common/model/BoxRespModel'
import { useLocation, useNavigate } from 'react-router-dom'
import { InboxInfoModel } from '@/common/model/InboxInfoModel'

export default function Component() {
  const { toast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  let inboxInfo: InboxInfoModel = location.state.inboxInfo;
  const inboxId = new URLSearchParams(location.search).get('inboxId');
  if (!inboxInfo) {
    // 传进来的 inboxInfo 为空, 从 url 中根据 inboxId 尝试获取.  还获取不到就展示空白页面
    if (!inboxId) {
      // 无效的 inboxId, 展示空白页面
      navigate('/404');
    }
    // 调用接口获取收件箱信息
    BoxRequest.post(BoxApi.getInboxInfo, { inboxId: inboxId })
      .then((resp: BoxRespModel<InboxInfoModel>) => {
        if (resp.success && null != resp.data) {
          inboxInfo = resp.data;
        } else {
          toast({
            title: "Get inbox info fail.",
            description: `get inbox info fail, err msg is ${resp.displayMsg}`,
          });
          //停顿两秒重定向到 404
          setTimeout(() => {
            navigate('/404');
          }, 2000);
        }
      }).catch((err) => {
        toast({
          title: "Get inbox info fail.",
          description: 'get inbox info fail',
        });
        setTimeout(() => {
          navigate('/404');
        }, 2000);
      });
  }



  let descriptionStr = inboxInfo.description;
  if (descriptionStr) {
    try {
      let parsedDescription = JSON.parse(descriptionStr);
      if (parsedDescription && parsedDescription.name) {
        descriptionStr = parsedDescription.name;
      }
    } catch (error) {
      console.info(`inbox descriptionStr is not a json string, inboxId:${inboxId}`);
    }
  }

  const [description, setDescription] = useState<string>(descriptionStr)
  // const [logo, setLogo] = useState<string>('')

  let expiryTime = new Date(inboxInfo.expiryTime);
  const expireTimeStr = `${expiryTime.getFullYear()}-${String(expiryTime.getMonth() + 1).padStart(2, '0')}-${String(expiryTime.getDate()).padStart(2, '0')}T${String(expiryTime.getHours()).padStart(2, '0')}:${String(expiryTime.getMinutes()).padStart(2, '0')}`;
  const [cutoffTime, setCutoffTime] = useState<string>(expireTimeStr)
  // const [rules, setRules] = useState('')

  const shareInboxByLink = () => {
    console.info(`shareInboxByLink clicked,inboxId:${inboxId}`)
    // 复制链接到剪贴板
    navigator.clipboard.writeText(`${window.location.origin}/inbox/${inboxId}/info`)
    toast({
      title: "Link Copied",
      description: "now you can share the link to others.",
    });
  }

  const jumpToInboxPage = () => {
    console.info(`jumpToInboxPage clicked,inboxId:${inboxId}`)
    navigate(`/inbox/${inboxId}/info`);
  }


  return (
    <div className="flex flex-col min-h-screen">
      <BoxPageHeader />
      <section className="py-10 px-12 bg-gray-100 max-w-screen-lg w-full mx-auto">
        <div className="flex flex-col items-center text-center space-y-4">
          <div className="background-[/placeholder.svg]">
            <h1 className="text-4xl font-bold text-gray-900">{inboxInfo.name}</h1>
          </div>
          <div className="w-full space-y-6">
            <div className="flex flex-col items-start">
              <Label className="mb-2 font-medium text-gray-900" htmlFor="inboxDescription">
                Inbox Description
              </Label>
              <Textarea className="p-2 border rounded w-full"
                id="inboxDescription"
                rows={3}
                value={description}
                disabled={true}
                onChange={(e) => setDescription(e.target.value)}
                placeholder='your description of the file collection task in this space' />
            </div>


            <div className="flex flex-col items-start">
              <Label className="mb-2 font-medium text-gray-900" htmlFor="inboxDeadline">
                Deadline Time.
              </Label>
              <div className="flex space-x-4">
                <Input className="p-2 border rounded" type="datetime-local" disabled
                  value={cutoffTime} onChange={(e) => setCutoffTime(e.target.value)} />
              </div>
            </div>

            <div className="flex flex-row items-center justify-center space-x-4 mt-4">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={shareInboxByLink}>
                Copy Link
              </button>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={jumpToInboxPage}>
                Jump to Inbox page
              </button>
            </div>


          </div>
        </div>
      </section>
      <BoxPageFooter />
    </div>
  )
}