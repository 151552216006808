import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter'


export default function Component() {

  return (
    <div className="flex flex-col min-h-screen justify-between">
      <BoxPageHeader />
      <h1>Terms of Service</h1>
      <p>Last updated: 2023-10-11</p>
      <p>Please read these terms of service ("Terms") carefully before using the "File Box" application (the "Service")
        operated by [Your Company Name] ("us," "we," or "our").</p>
      <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These
        Terms apply to all visitors, users, and others who access or use the Service.</p>
      <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms,
        then you may not access the Service.</p>
      <h2>1. Description of Service</h2>
      <p>The "File Box" app allows users to create a file inbox to collect and manage digital files. The service may include
        features such as file storage, sharing, and organization tools.</p>
      <h2>2. User Accounts</h2>
      <p>Users must provide accurate and complete information when creating an account.</p>
      <p>Users are responsible for safeguarding their account credentials and are liable for any activity occurring under
        their accounts.</p>
      <h2>3. Content and Use of Service</h2>
      <p>Users are responsible for the content they upload and share through the Service.</p>


      <BoxPageFooter />
    </div>
  )
}