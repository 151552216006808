export default function Component() {

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>File Uploaded</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="alert alert-success" role="alert">
                                            <strong>Success!</strong> Your file has been uploaded successfully.
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <a href="/inbox/create" className="btn btn-primary">Create My Inbox</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
