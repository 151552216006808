export class InboxInfoModel {
  /** 收件夹ID */
  inboxId: string = '';
  /** 收件夹所属用户ID */
  ownerUserId: string = '';
  /** 收件夹名称 */
  name: string = '';
  /** 是否有密码，1:是，0:无 */
  hasPwd: boolean = false;
  /** 描述摘要 */
  descDigest: string = '';
  /** 收件夹描述 */
  description: string = '';
  /** 收件夹状态，draft:草稿，disabled:禁用，ok:正常，expired:已过期 */
  state: 'draft' | 'disabled' | 'ok' | 'expired' = 'draft';
  /** 通知规则，none:不通知，each_upload:每次上传 */
  notifyRule: 'none' | 'each_upload' = 'none';
  /** 是否在到期时邮件通知 */
  notifyOnExpiry: boolean = false;
  /** 通知邮箱，不为空则进行通知 */
  notifyEmail: string = '';
  /** 是否限制文件类型 */
  limitFileTypeFlag: boolean = false;
  /** 允许的文件类型 */
  allowedFileTypes: string = '';
  /** 最大文件大小，单位:byte */
  maxFileSize: number = 0;
  /** 最大文件数量 */
  maxFileCount: number = 0;
  /** 当前文件数量 */
  fileCount: number = 0;
  /** 到期时间,单位 ms */
  expiryTime: number = Date.now();
  /** 创建时间,单位 ms */
  ctime: number = Date.now();
  /** 修改时间,单位 ms */
  mtime: number = Date.now();
}
/*  数据样例

{
  "inboxId": "boxved7fNe65yjn8n5M8BnD3HSG53HG5",
  "ownerUserId": "uid6s8mtxltjX0truYoxgijnu8gDELxK",
  "name": "test",
  "hasPwd": false,
  "descDigest": "{\"name\":\"test\"}",
  "description": "{\"name\":\"test\"}",
  "state": "draft",
  "notifyRule": "none",
  "notifyOnExpiry": false,
  "notifyEmail": null,
  "limitFileTypeFlag": false,
  "allowedFileTypes": null,
  "maxFileSize": 10485760,
  "maxFileCount": 0,
  "fileCount": 0,
  "expiryTime": 1707287850000,
  "ctime": 1704609451000,
  "mtime": 1704609451000
}
 
 */