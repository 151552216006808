
import { Button } from "@/components/ui/button"
import { CardContent, Card } from "@/components/ui/card"
import { Check } from "lucide-react"
import BoxApi from "@/common/BoxApi"
import boxRequest from "@/common/util/BoxRequest"
import { useEffect, useState } from "react"
import { BoxRespModel } from "@/common/model/BoxRespModel"
import { SubscriptionPlanModel } from "@/common/model/response/SubscriptionModel"
import { useToast } from "@/components/ui/use-toast"

export default function Component() {

  const { toast } = useToast();

  const [currentPlan, setCurrentPlan] = useState<string>('')

  useEffect(() => {
    //获取当前订阅计划
    if (boxRequest.getLoginFlag()) {
      boxRequest.get(BoxApi.currentPlan, {}).then((resp: BoxRespModel<SubscriptionPlanModel>) => {
        if (!resp || !resp.success || !resp.data) {
          toast({
            variant: "destructive",
            title: "Failed to Fetch current plan",
          })
          return;
        }
        setCurrentPlan(resp.data.planCode);
      }).catch((err) => {
        toast({
          variant: "destructive",
          title: "Failed to Fetch current plan",
          description: err.message,
        })
      })
    }
  }, [toast])





  return (
    <div className="flex flex-col justify-between">

      <section className="w-full py-12 md:py-24 lg:py-32" id="pricing">
        <div className="container px-4 md:px-6">
          <h2 className="text-3xl font-bold tracking-tighter text-center sm:text-5xl">Our Pricing Plans</h2>
          <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400 text-center mx-auto my-8">
            Choose the best plan that fits your needs. Upgrade, downgrade, or cancel at any time.
          </p>
          <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 justify-center items-center">
            <Card className="w-full max-w-xs">
              <CardContent className="p-4 min-h-[300px]">
                <h4 className="text-lg font-semibold">Free</h4>
                <p className="text-gray-500 dark:text-gray-400">For simple demand</p>
                <div className="flex items-center justify-between mt-4">
                  <h2 className="text-3xl font-bold">Free</h2>
                  <p className="text-gray-500 dark:text-gray-400 text-center mr-2">/month</p>
                </div>
                <ul className="grid gap-2 py-4">
                  <li>
                    <Check className="mr-2 inline-block h-4 w-4" />
                    500MB Total Storage
                  </li>
                  <li>
                    <Check className="mr-2 inline-block h-4 w-4" />
                    Up to 2 Inboxs
                  </li>
                  <li>
                    <Check className="mr-2 inline-block h-4 w-4" />
                    Batch Download
                  </li>
                </ul>
                <Button disabled={currentPlan === 'free'}>
                  {currentPlan === 'free' ? 'Current Plan' : 'Choose Plan'}
                </Button>
              </CardContent>
            </Card>
            <Card className="w-full max-w-xs">
              <CardContent className="p-4 min-h-[300px]">
                <h4 className="text-lg font-semibold">Basic</h4>
                <p className="text-gray-500 dark:text-gray-400">Perfect for individual users</p>
                <div className="flex items-center justify-between mt-4">
                  <h2 className="text-3xl font-bold">$5</h2>
                  <p className="text-gray-500 dark:text-gray-400 text-center mr-2">/month</p>
                </div>
                <ul className="grid gap-2 py-4">
                  <li>
                    <Check className="mr-2 inline-block h-4 w-4" />
                    5 GB Storage
                  </li>
                  <li>
                    <Check className="mr-2 inline-block h-4 w-4" />
                    Up to 10 Inboxs
                  </li>
                  <li>
                    <Check className="mr-2 inline-block h-4 w-4" />
                    Ticket Support
                  </li>
                  <li>
                    <Check className="mr-2 inline-block h-4 w-4" />
                    Email Notify
                  </li>
                </ul>
                <Button disabled={currentPlan === 'basic'}>
                  {currentPlan === 'basic' ? 'Current Plan' : 'Choose Plan'}
                </Button>
              </CardContent>
            </Card>
            <Card className="w-full max-w-xs">
              <CardContent className="p-4 min-h-[300px]">
                <h4 className="text-lg font-semibold">Pro</h4>
                <p className="text-gray-500 dark:text-gray-400">Professional for individual users</p>
                <div className="flex items-center justify-between mt-4">
                  <h2 className="text-3xl font-bold">$15</h2>
                  <p className="text-gray-500 dark:text-gray-400 text-center mr-2">/month</p>
                </div>
                <ul className="grid gap-2 py-4">
                  <li>
                    <Check className="mr-2 inline-block h-4 w-4" />
                    50 GB Storage
                  </li>
                  <li>
                    <Check className="mr-2 inline-block h-4 w-4" />
                    Ticket Support
                  </li>
                  <li>
                    <Check className="mr-2 inline-block h-4 w-4" />
                    Up to 20 Inboxs
                  </li>
                  <li>
                    <Check className="mr-2 inline-block h-4 w-4" />
                    Email Notify
                  </li>
                </ul>
                <Button disabled={currentPlan === 'pro'}>
                  {currentPlan === 'pro' ? 'Current Plan' : 'Choose Plan'}
                </Button>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

    </div>
  )
}