import { Button } from "@/components/ui/button"

export default function ContactUsPage() {

    return (
        <div className="flex flex-col justify-between">
            <section className="py-10 px-12 text-center">
                <h2 className="text-3xl font-bold mb-6">Contact Us</h2>
                <p className="text-lg mb-6">Have any questions or need help? Feel free to contact us.</p>
                <Button className="bg-blue-500 text-white">Contact Us</Button>
            </section>
        </div>
    )

}