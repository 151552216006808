import BoxApi from "@/common/BoxApi";
import UserInfoMeModel from "@/common/model/UserInfoMeModel";
import BoxRequest from "@/common/util/BoxRequest";
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast";
import { useEffect, useState } from "react"


export default function ProfileForm() {
    const [nickname, setNickname] = useState<string>('');
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const { toast } = useToast();

    const loadUserInfo = () => {
        BoxRequest.getUserInfo().then((res: UserInfoMeModel | null) => {
            if (null === res || null === res.nickname) {
                return;
            }
            setNickname(res.nickname);
        });
    }

    useEffect(() => {
        loadUserInfo();
    }, []);

    useEffect(() => {
        checkCanSubmit();
    }, [nickname]);

    const handleSubmit = () => {
        // 提交昵称数据
        BoxRequest.post(BoxApi.updateProfileInfo, { nickname: nickname })
            .then((res) => {
                if (res.success) {
                    toast({
                        title: "Update success",
                        duration: 1500
                    })
                    // 删除 localStorage 中的用户信息
                    BoxRequest.removeLocalUserInfo();
                    //延迟1s 刷新页面
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    toast({
                        title: "Update failed",
                        description: res.debugMsg,
                        duration: 3000,
                    })
                }
            }).catch((err) => {
                toast({
                    title: "Update failed",
                    description: err.message,
                    duration: 3000,
                })
            })
    }

    const checkCanSubmit = async () => {
        const nicknameRegex = /^[\p{L}\p{N}_\p{Script=Han}]{3,20}$/u;

        if (!nickname || !nicknameRegex.test(nickname)) {
            setCanSubmit(false);
            return;
        }

        let userInfo = await BoxRequest.getUserInfo();
        if (userInfo && userInfo.nickname === nickname) {
            // 提交按钮不可点击
            setCanSubmit(false);
            return;
        }
        setCanSubmit(true);
    }



    return (
        <div className="space-y-8">
            <div>
                <label>Name</label>
                <Input className="focus-visible:ring-gray-400 focus-visible:ring-1"
                    placeholder="Your name"
                    value={nickname}
                    onChange={(e) => {
                        setNickname(e.target.value);
                    }} />
                <p className="text-sm text-muted-foreground">
                    This is the name that will be displayed on your profile and in emails.
                </p>
            </div>
            <Button disabled={!canSubmit} onClick={() => { handleSubmit() }}>Update account</Button>
        </div>
    )
}