import { Link } from "react-router-dom"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import BoxRequest from "@/common/util/BoxRequest";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AvatarDropDown from "@/pages/setting/AvatarDropDown";
import UserInfoMeModel from "@/common/model/UserInfoMeModel";


interface BoxPageHeaderProps {
  //默认背景 class name  bg-transparent
  defaultBgClassName?: string;
  //滚动后的背景 class name, 例如:bg-blue-100 bg-opacity-50
  scrollBgClassName?: string;
}

const BoxPageHeader: React.FC<BoxPageHeaderProps> = ({ defaultBgClassName = 'bg-transparent', scrollBgClassName = 'bg-blue-100 bg-opacity-50' }) => {

  const [loginFlag, setLoginFlag] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [avartar, setAvartar] = useState('/placeholder.svg');

  const headerRef = useRef<HTMLDivElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();


  useEffect(() => {


    setLoginFlag(BoxRequest.getLoginFlag());

    BoxRequest.getUserInfo().then((userInfo: UserInfoMeModel | null) => {
      if (userInfo) {
        setAvartar(userInfo?.headUrl || '/placeholder.svg');
      }
    });



    // 页面滚动监听
    const onScroll = () => {
      setScrollPosition(window.scrollY);
    }
    window.addEventListener('scroll', onScroll);

    // header组件高度与占位高度保持一致
    const resizeObserver = new ResizeObserver(entries => {
      if (divRef.current) {
        const headerHeight = entries[0].target.getBoundingClientRect().height;
        divRef.current.style.height = `${headerHeight}px`;
      }
    });
    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
    }

    //卸载函数
    return () => {
      window.removeEventListener('scroll', onScroll);
      resizeObserver.disconnect();
    }
  }, []);


  return (
    <>
      <header ref={headerRef} className={`fixed w-full text-gray-700 py-3 px-6 flex justify-between items-center select-none ${scrollPosition <= 64 ? defaultBgClassName : scrollBgClassName}`}>
        <div className="text-2xl font-bold text-black cursor-pointer"
          onClick={() => navigate('/')}> fileinbox.online</div>

        <nav className="flex gap-6 items-center">
          {loginFlag ? (
            <Link className="text-lg hover:text-blue-500" to="/home">
              Home
            </Link>) : null}
          <Link className="text-lg hover:text-blue-500" to="/#faqs">
            FAQs
          </Link>
          {/* target="_blank" */}
          <Link className="text-lg hover:text-blue-500" to="/#pricing">
            Pricing
          </Link>
          <Link className="text-lg hover:text-blue-500" to="/#contact-us">
            Contact Us
          </Link>
          {!loginFlag && (
            <Link className="text-lg hover:text-blue-500" to="/signin">
              Sign In
            </Link>
          )}
          {loginFlag && (
            <div className="cursor-pointer">
              <AvatarDropDown>
                <Avatar>
                  <AvatarImage src={avartar} />
                  <AvatarFallback>CN</AvatarFallback>
                </Avatar>
              </AvatarDropDown>
            </div>
          )}
        </nav>
      </header>
      <div ref={divRef} className="py-6 w-full">
        {/* 占用位置, 防止正文内容被 header 覆盖 */}
      </div>
    </>
  )
}

const BoxPageFooter = () => {

  const navigate = useNavigate();

  return (
    <footer className="w-full  bg-blue-50 text-gray-700 py-8 px-6">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-2xl font-bold cursor-pointer" onClick={() => navigate('/')}>
          fileinbox.online</div>
        <nav className="flex gap-6">
          {/* <Link className="text-lg hover:text-blue-500" to="#">
            Login
          </Link> */}
          <Link className="text-lg hover:text-blue-500" to="/home">
            Home
          </Link>
          <Link className="text-lg hover:text-blue-500" to="/faqs">
            FAQs
          </Link>
          {/* <Link className="text-lg hover:text-blue-500" to="#">
            Pricing
          </Link> */}
          <Link className="text-lg hover:text-blue-500" to="/contact-us">
            Contact Us
          </Link>
        </nav>
      </div>
    </footer>
  )
}

export { BoxPageHeader, BoxPageFooter }