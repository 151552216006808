import { BoxPageHeader } from '@/components/BoxHeaderFooter'
import { useNavigate } from 'react-router-dom'
import BoxRequest from '@/common/util/BoxRequest'
import { useEffect, useState } from 'react'
import { Button } from "@/components/ui/button"
import { TableHead, TableRow, TableHeader, TableCell, TableBody, Table } from "@/components/ui/table"
import CustomPagination from '@/components/CustomPagination'
import { DropdownMenuTrigger, DropdownMenuItem, DropdownMenuContent, DropdownMenu } from "@/components/ui/dropdown-menu"
import BoxApi from '@/common/BoxApi'
import { PageQueryInboxReqModel } from '@/common/model/request/InboxReqModel'
import { BoxRespModel, PageRespModel } from '@/common/model/BoxRespModel'
import { toast } from '@/components/ui/use-toast'
import { InboxInfoModel } from '@/common/model/InboxInfoModel'
import { Share ,SquarePen,CalendarCheck,EllipsisVertical } from 'lucide-react'

export default function Component() {

    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [inboxList, setInboxList] = useState<InboxListItem[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>(0);

    const loadInboxList = (pageNo: number, pageSize: number) => {
        let reqModel = new PageQueryInboxReqModel();
        reqModel.pageNo = pageNo;
        reqModel.pageSize = pageSize;
        BoxRequest.post(BoxApi.pageQueryInbox, reqModel)
            .then((resp: BoxRespModel<PageRespModel<InboxInfoModel>>) => {
                console.log(resp)
                // 查询收件夹信息
                if (!resp.data) {
                    throw new Error('no data');
                }
                // 获取收件夹信息, 渲染页面
                let pageResp: PageRespModel<InboxInfoModel> = resp.data;
                setCurrentPage(pageResp.current);
                setTotalPages(pageResp.pages);
                setTotalCount(pageResp.total);
                if (0 === pageResp.size) {
                    //TODO: 显示空页面
                    console.info(`inbox list is empty, currentPage:${pageResp.current}, totalPages:${pageResp.pages}`);
                    return;
                }
                setInboxList(InboxListItem.fromInboxInfoModelList(pageResp.records));

            }).catch((err) => {
                console.warn(`query inbox list fail, err is ${err}`)
                toast({
                    variant: 'destructive',
                    title: "Query FAIL",
                    description: `${err}`,
                });
            })
    }


    useEffect(() => {
        if (!BoxRequest.getLoginFlag()) {
            //未登录,跳转到登录页
            navigate('/signin');
            return;
        }


        //TODO: 播放加载动画


        //TODO: loading new page data.
        loadInboxList(currentPage, pageSize);

    }, [currentPage, pageSize, navigate])


    return (
        <div className="flex flex-col min-h-screen">
            <BoxPageHeader />
            <div key="1" className="p-6 bg-white shadow rounded-lg">
                <div className="mb-4 flex justify-between items-center">
                    <h1 className="text-2xl font-semibold">Inbox</h1>
                    <Button variant="outline" onClick={() => navigate('/inbox/create')}>Create Inbox</Button>
                </div>
                <div className="overflow-auto">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Title</TableHead>
                                <TableHead className="w-[200px]">Create time</TableHead>
                                <TableHead className="w-[200px]">deadline time</TableHead>
                                <TableHead className="w-[100px] text-right">operate</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {inboxList.map((row: InboxListItem, index) => (
                                <TableRow key={index}>
                                    <TableCell className="cursor-pointer" onClick={()=>navigate(`/inbox/${row.inboxId}/manage/files`)}>{row.name}</TableCell>
                                    <TableCell>{row.ctime}</TableCell>
                                    <TableCell>{row.expiryTime}</TableCell>
                                    <TableCell className="flex justify-end items-center space-x-2">
                                        {/* <EyeIcon className="h-5 w-5 text-gray-400" /> */}
                                        <Share className={`h-5 w-5 text-gray-400 hover:text-gray-600 ${row.shareable ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                        <SquarePen className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer" />
                                        <CalendarCheck className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer" />
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <div>
                                                    <EllipsisVertical className="h-5 w-5 text-gray-400 cursor-pointer" />
                                                </div>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent align="end">
                                                <DropdownMenuItem>Copy</DropdownMenuItem>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </div>
                <div className="mt-6 flex justify-end">
                    <CustomPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalCount={totalCount}
                        initPageSize={pageSize}
                        onPageChange={(newPage, newPageSize) => { setCurrentPage(newPage); setPageSize(newPageSize) }} />
                </div>
            </div>

        </div>
    )
}


class InboxListItem {
    inboxId: string = '';
    name: string | null = '';
    ctime: string | null = '';
    expiryTime: string | null = '';
    shareable: boolean = false;

    // 从 InboxInfoModel 转换为 InboxListItem
    static fromInboxInfoModel(inboxInfo: InboxInfoModel): InboxListItem {
        let item = new InboxListItem();
        item.inboxId = inboxInfo.inboxId;
        item.name = inboxInfo.name;
        item.ctime = new Date(inboxInfo.ctime).toLocaleString();
        item.expiryTime = new Date(inboxInfo.expiryTime).toLocaleString();
        item.shareable = ('ok' === inboxInfo.state);
        return item;
    }

    //批量转换
    static fromInboxInfoModelList(inboxInfoList: InboxInfoModel[]): InboxListItem[] {
        return inboxInfoList.map((inboxInfo) => InboxListItem.fromInboxInfoModel(inboxInfo));
    }
}