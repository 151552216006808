import { Search as SearchIcon } from "lucide-react"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion"
import { Input } from "@/components/ui/input"
import { useState } from "react"



export default function FaqPage() {

    const [searchContent, setSearchContent] = useState<string>('');

    // 过滤出包含搜索关键词的问题
    const filteredFaqs = faqs.map(category => ({
        ...category,
        questions: category.questions.filter(question => {
            const regex = new RegExp(searchContent, 'i');
            return regex.test(question.title) || question.answerLines.some(line => line && regex.test(line));
        })
    })).filter(category => category.questions.length > 0);

    return (
        <div className="flex flex-col min-h-screen justify-between">
            <div>
                <section className="w-full py-12 md:py-24 lg:py-32">
                    <div className="container px-4 md:px-6">
                        <div className="mx-auto max-w-4xl space-y-6">
                            <div className="space-y-2 text-center">
                                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                                    Frequently Asked Questions
                                </h1>
                                <p className="text-gray-500 dark:text-gray-400 md:text-xl/relaxed">
                                    Find the answers you need quickly and easily.
                                </p>
                            </div>
                            <div className="relative">
                                <SearchIcon className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-500 dark:text-gray-400" />
                                <Input
                                    className="w-full rounded-md border border-gray-200 bg-white px-12 py-3 text-sm shadow-sm transition-colors focus:border-gray-700 focus:outline-none focus:ring-1 focus:ring-gray-700 dark:border-gray-700 dark:bg-gray-750 dark:text-gray-50 dark:focus:border-gray-50 dark:focus:ring-gray-50"
                                    placeholder="Search FAQs"
                                    type="search"
                                    value={searchContent}
                                    onChange={(e) => setSearchContent(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="w-full py-12 md:py-12 lg:py-12">
                    <div className="container px-4 md:px-6">
                        <div className="mx-auto max-w-4xl space-y-8">
                            {filteredFaqs.map((category, i) => (
                                <div className="space-y-4" key={i}>
                                    <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl">{category.category}</h2>
                                    <Accordion collapsible type="single">
                                        {category.questions.map((question, j) => (
                                            <AccordionItem value={`feature-${j}`} key={j}>
                                                <AccordionTrigger className="flex items-center justify-between">
                                                    <h3 className="text-lg font-semibold">{question.title}</h3>
                                                </AccordionTrigger>
                                                <AccordionContent>
                                                    {question.answerLines.map((line, k) => (
                                                        <p key={k} className="text-sm text-gray-500 py-2 dark:text-gray-400">
                                                            {line}
                                                        </p>
                                                    ))}
                                                </AccordionContent>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

const faqs = [
    {
        category: "Product Features",
        questions: [
            {
                title: "What features are included in the product?",
                answerLines: [
                    "Our product includes a wide range of features to help you achieve your goals. Some of the key features include:",
                    "✔️ Easy collect files",
                    "✔️ Customizable file inbox name and file size",
                    "✔️ Automated notify when file collected",
                    "✔️ Secure data storage and backup"
                ]
            }, {
                title: "How often are new features added to the product?",
                answerLines: [
                    "We are constantly working on improving and expanding our product to better serve our customers. New features are typically added on a quarterly basis, with smaller updates and bug fixes released more frequently."
                ]
            }
        ]
    }, {
        category: "Pricing",
        questions: [
            {
                title: "What are the pricing options for the product?",
                answerLines: [
                    "We offer several pricing plans to fit the needs of businesses of all sizes:",
                    "✔️ Free plan: $0 per month, up to 500MB storage, up to 2 inboxes,limited features",
                    "✔️ Starter plan: $5 per month, up to 5GB storage, full features",
                    "✔️ Pro plan: $15 per month, up to 50GB storage, full features",
                    "All plans include the full set of features, and you can upgrade or downgrade at any time."
                ]
            }, {
                title: "Are there any discounts or special offers available?",
                answerLines: [
                    "Yes, we do offer several discounts and special offers:",
                    "✔️ Annual billing: Get 2 months free with annual payment",
                    "✔️ Referral program: Get 1 month free for each new customer you refer",
                    "If you have any other questions about our pricing or special offers, please don't hesitate to contact our team."
                ]
            }
        ]
    }, {
        category: "Account Management",
        questions: [
            {
                title: "How do I create an account?",
                answerLines: [
                    "Creating an account is easy! Simply visit our website and click the \"Sign Up\" button in the top right corner. You'll be prompted to enter your basic information, such as your name, email address, and a secure password.",
                    "Once you've completed the sign-up process, you'll receive a confirmation email with instructions on how to activate your account and get started."
                ]
            }, {
                title: "How do I manage my account settings?",
                answerLines: [
                    "To manage your account settings, simply log in to your account and navigate to the \"Settings\" page. Here, you'll be able to update your personal information, change your password, and adjust your notification preferences.",
                    "If you need to make any changes to your billing information or subscription plan, you can do so by visiting the \"Billing\" section of your account settings."
                ]
            }, {
                title: "How do I cancel my subscription?",
                answerLines: [
                    "To cancel your subscription, simply log in to your account and navigate to the \"Billing\" section. From there, you'll be able to view your current subscription plan and cancel it if desired.",
                    "Please note that we require a 30-day notice for subscription cancellations. If you have any questions or concerns about cancelling your subscription, please don't hesitate to contact our customer support team."
                ]
            }
        ]
    }, {
        category: "Customer Support",
        questions: [
            {
                title: "How can I get in touch with customer support?",
                answerLines: [
                    "We have several channels available for you to get in touch with our customer support team:",
                    "Email: support@fileinbox.online",
                    // "Support ticket: Submit a request through our online form",
                    "Our support team is dedicated to providing prompt and helpful assistance. We strive to respond to all inquiries within one business day."
                ]
            }
        ]
    }
]