import { useState, useEffect } from 'react';
import { useToast } from '@/components/ui/use-toast';
import BoxApi from '@/common/BoxApi';
import BoxRequest from '@/common/util/BoxRequest';
import { BoxRespModel } from '@/common/model/BoxRespModel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { InboxInfoModel } from '@/common/model/InboxInfoModel';
import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter';
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Progress } from "@/components/ui/progress"
import CryptoJS from 'crypto-js';


export default function Component() {

    const { toast } = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const { inboxId } = useParams();
    // console.info('从url中获取的inboxId:', inboxId);

    const defaultInboxInfo: InboxInfoModel = new InboxInfoModel();
    const [inboxInfo, setInboxInfo] = useState<InboxInfoModel>(location.state?.inboxInfo || defaultInboxInfo);
    const [description, setDescription] = useState<string | undefined>(inboxInfo?.description);
    const [cutoffTime, setCutoffTime] = useState<string>('');
    //用户输入的密码
    const [inputPassword, setInputPassword] = useState<string>('');
    //上传文件进度
    const [uploadProgressNumber, setUploadProgressNumber] = useState<number>(0);

    //初始化, 获取inbox信息, 展示页面
    const fetchInboxInfo = async () => {
        if (!inboxId)
            return;
        try {
            const resp: BoxRespModel<InboxInfoModel> = await BoxRequest.post(BoxApi.getInboxInfo, { inboxId: inboxId });
            if (resp.success && null != resp.data) {
                setInboxInfo(resp.data);
                let descriptionStr = resp.data.description;
                try {
                    descriptionStr = JSON.parse(descriptionStr)?.name ?? descriptionStr;
                } catch (err) {
                    console.error(`Parse description fail: ${err}`);
                }
                setDescription(descriptionStr);
                let expiryTime = new Date(resp.data.expiryTime);
                const expireTimeStr = `${expiryTime.getFullYear()}-${String(expiryTime.getMonth() + 1).padStart(2, '0')}-${String(expiryTime.getDate()).padStart(2, '0')}T${String(expiryTime.getHours()).padStart(2, '0')}:${String(expiryTime.getMinutes()).padStart(2, '0')}`;
                setCutoffTime(expireTimeStr);

            } else {
                throw new Error(resp.displayMsg || 'Get inbox info fail.');
            }
        } catch (err) {
            toast({
                title: "Get inbox info fail.",
                description: `${err}`,
            });
            setTimeout(() => {
                navigate('/404');
            }, 2000);
        }

    };

    useEffect(() => {
        fetchInboxInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inboxId]);



    // 提交文件按钮逻辑
    const submitFiles = () => {
        console.info('submitFiles clicked!');
        //检查文件输入框是否已经选中文件，未选中给toast提示
        const inputFile = document.getElementById('inputFile') as HTMLInputElement;
        if (!inputFile.files || inputFile.files.length === 0) {
            toast({
                title: 'No file selected!',
                description: 'Please select a file to submit.',
            });
            return;
        }
        const file = inputFile.files[0];
        console.info('file:', file);

        // 调用收件夹的上传文件接口,将文件上传过去
        BoxRequest.postWithFiles(BoxApi.uploadFile, {
            inboxId: inboxId,
            fileName: file.name,
            fileSize: file.size,
            file: file,
            pwdHash: inboxInfo.hasPwd ? CryptoJS.SHA256(inputPassword).toString(CryptoJS.enc.Hex) : '',
        }, (progress: number) => {
            // 上传文件进度
            console.info('upload file progress:', progress);
            setUploadProgressNumber(progress);
        }).then((resp) => {
            if (resp.success) {
                toast({
                    title: 'Submit file SUCCESS!',
                    description: 'The file has been submitted successfully.',
                });
                //显示hover 覆盖在当前组件上
                setTimeout(() => {
                    navigate('/inbox/uploadComplete');
                }, 2000);
            } else {
                toast({
                    variant: "destructive",
                    title: 'Submit FAIL!',
                    description: `${resp.displayMsg}`,
                });
                setUploadProgressNumber(0);
            }
        }).catch((err) => {
            toast({
                variant: "destructive",
                title: 'Submit FAIL!',
                description: `${err}`,
            });
            setUploadProgressNumber(0);
        });

    }


    function renderContent(status: string) {
        switch (status) {

            case 'ok': return (
                <div className="flex flex-col items-center text-center space-y-4">
                    <div className="background-[/placeholder.svg]">
                        <h1 className="text-4xl font-bold text-gray-900">{inboxInfo.name}</h1>
                    </div>
                    <div className="w-full space-y-6">

                        <div className="flex flex-col items-start">
                            <Label className="mb-2 font-medium text-gray-900" htmlFor="inboxDescription">
                                Description:
                            </Label>
                            <Textarea className="p-2 border rounded w-full"
                                id="inboxDescription"
                                rows={3}
                                value={description}
                                disabled={true}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder='your description of the file collection task in this space' />
                        </div>


                        <div className="flex flex-col items-start">
                            <Label className="mb-2 font-medium text-gray-900" htmlFor="inboxDeadline">
                                Deadline Time.
                            </Label>
                            <div className="flex space-x-4">
                                <Input className="p-2 border rounded" type="datetime-local" disabled
                                    value={cutoffTime} onChange={(e) => setCutoffTime(e.target.value)} />
                            </div>
                        </div>

                        {inboxInfo.hasPwd && (
                            <div className="flex flex-col items-start">
                                <Label className="mb-2 font-medium text-gray-900" htmlFor="inboxDeadline">
                                    Inbox Password:
                                </Label>
                                <div className="flex space-x-4">
                                    <Input className="p-2 border rounded" type="password" placeholder="Please Input Inbox Password"
                                        value={inputPassword} onChange={(e) => setInputPassword(e.target.value)} />
                                </div>
                            </div>
                        )}

                        <div className="flex flex-col items-start">
                            <Label className='mb-2 font-medium text-gray-900' htmlFor="inputFile">File</Label>
                            <Input id="inputFile" type="file" />
                        </div>

                        {/* 上传进度 */}
                        {uploadProgressNumber > 0 && (
                            <div className="flex flex-col items-start">
                                <Progress value={uploadProgressNumber} />
                            </div>
                        )}

                        <div className="flex flex-row items-center justify-center space-x-4 mt-4">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                onClick={submitFiles}>
                                Submit File
                            </button>

                        </div>


                    </div>
                </div>
            );
            case 'draft':
                return (
                    <div>
                        <Label className="mb-2 font-medium text-gray-900" htmlFor="inboxDescription">
                            The Inbox is in DRAFT mode! Please wait for the owner to publish it.
                        </Label>
                    </div>
                );
            case 'disabled':
                return (
                    <div>
                        <Label className="mb-2 font-medium text-gray-900" htmlFor="inboxDescription">
                            The Inbox is DISABLED! cannot submit any file.
                        </Label>
                    </div>
                );
            case 'expired':
                return (
                    <div>
                        <Label className="mb-2 font-medium text-gray-900" htmlFor="inboxDescription">
                            The Inbox is EXPIRED! cannot submit any file.
                        </Label>
                    </div>
                );
            default: return (
                <div>
                    <Label className="mb-2 font-medium text-gray-900" htmlFor="inboxDescription">
                        The Inbox is is in an unknown status! Please contact the owner.
                    </Label>
                </div>
            )
        }
    }


    return (
        <div className="flex flex-col min-h-screen">
            <BoxPageHeader />
            <section className="py-10 px-12 bg-gray-100 max-w-screen-lg w-full mx-auto">
                {renderContent(inboxInfo.state)}
            </section>
            <BoxPageFooter />
        </div>
    )
}