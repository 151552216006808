import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter'
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
//import { Checkbox } from "@/components/ui/checkbox"
import { useState } from 'react'
import { CreateInboxModel } from '@/common/model/request/InboxReqModel'
import { useToast } from '@/components/ui/use-toast'
import BoxApi from '@/common/BoxApi'
import BoxRequest from '@/common/util/BoxRequest'
import { BoxRespModel } from '@/common/model/BoxRespModel'
import { InboxInfoModel } from '@/common/model/InboxInfoModel'
import { useNavigate } from 'react-router-dom'

export default function Component() {
  const { toast } = useToast();
  const navigate = useNavigate();


  const today = new Date();
  //YYYY-MM-DDThh:mm
  const currentTimeStr = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}T${String(today.getHours()).padStart(2, '0')}:${String(today.getMinutes()).padStart(2, '0')}`;
  const sevenDayLater = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
  const sevenDayLaterStr = `${sevenDayLater.getFullYear()}-${String(sevenDayLater.getMonth() + 1).padStart(2, '0')}-${String(sevenDayLater.getDate()).padStart(2, '0')}T${String(sevenDayLater.getHours()).padStart(2, '0')}:${String(sevenDayLater.getMinutes()).padStart(2, '0')}`;




  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  // const [logo, setLogo] = useState<string>('')
  const [cutoffTime, setCutoffTime] = useState<string>(sevenDayLaterStr)
  // const [rules, setRules] = useState('')

  // 检查参数
  const checkParam = (): boolean => {
    if (!title.trim()) {
      toast({
        variant: "destructive",
        title: "Create Fail",
        description: 'Title cannot be empty',
      })
      return false;
    }

    if (!description.trim()) {
      toast({
        variant: "destructive",
        title: "Create Fail",
        description: 'Description cannot be empty',
      })
      return false;
    }

    const cutoffDate = new Date(cutoffTime);
    if (cutoffDate.getTime() < Date.now()) {
      toast({
        variant: "destructive",
        title: "Create Fail",
        description: 'Deadline time cannot be in the past',
      })
      return false;
    }
    return true;
  }

  // 创建收件夹功能
  const createInbox = () => {
    if (!checkParam()) {
      return;
    }

    const createInboxModel: CreateInboxModel = {
      name: title,
      description: `{"name":"${description}"}`,
      hasPwd: false,
      maxFileCount: 50,
      maxFileSize: 10 * 1024 * 1024,
      notifyOnExpiry: false,
      notifyEmail: null,
    }

    BoxRequest.post(BoxApi.createInbox, createInboxModel)
      .then((resp: BoxRespModel<InboxInfoModel>) => {
        //判断是否成功
        if (resp.success) {
          toast({
            title: "Create Success",
            description: 'Create Inbox Success',
          })
          const inboxInfoDto = resp.data;
          // 跳转到链接分享页面
          navigate(`/inbox/share?inboxId=${resp.data?.inboxId ?? ''}`, { state: { inboxInfo: inboxInfoDto } });
        } else {
          toast({
            variant: "destructive",
            title: "Create Failed",
            description: resp.displayMsg
          })
        }
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Create Failed",
          description: err.message,
        })
      })
  }


  return (
    <div className="flex flex-col min-h-screen">
      <BoxPageHeader />
      <section className="py-10 px-12 bg-gray-100 max-w-screen-lg w-full mx-auto">
        <div className="flex flex-col items-center text-center space-y-4">
          <div className="background-[/placeholder.svg]">
            <h1 className="text-4xl font-bold text-gray-900">Create File</h1>
          </div>
          <div className="w-full space-y-6">
            <div className="flex flex-col items-start">
              <Label className="mb-2 font-medium text-gray-900" htmlFor="inboxTitle">
                Inbox Subject
              </Label>
              <Input className="p-2 border rounded w-full" id="inboxTitle" type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder='place file inbox title here' />
            </div>
            <div className="flex flex-col items-start">
              <Label className="mb-2 font-medium text-gray-900" htmlFor="inboxDescription">
                Inbox Description
              </Label>
              <Textarea className="p-2 border rounded w-full"
                id="inboxDescription"
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder='your description of the file collection task in this space' />
            </div>


            <div className="flex flex-col items-start">
              <Label className="mb-2 font-medium text-gray-900" htmlFor="inboxDeadline">
                Deadline Time.
              </Label>
              <div className="flex space-x-4">
                <Input className="p-2 border rounded" type="datetime-local" min={currentTimeStr}
                  value={cutoffTime} onChange={(e) => setCutoffTime(e.target.value)} />
              </div>
            </div>

            <div className="flex flex-col items-start mt-4">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={createInbox}>
                Create
              </button>
            </div>


          </div>
        </div>
      </section>
      <BoxPageFooter />
    </div>
  )
}