import BoxApi from "@/common/BoxApi";
import { BoxRespModel } from "@/common/model/BoxRespModel";
import BoxRequest from "@/common/util/BoxRequest";
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast";
import { useEffect, useRef, useState } from "react"
import JSEncrypt from "jsencrypt";

export default function AccountForm() {
    const [oldPwd, setOldPwd] = useState<string>('');
    const [newPwd, setNewPwd] = useState<string>('');
    const [newPwdConfirm, setNewPwdConfirm] = useState<string>('');
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const { toast } = useToast();
    const rsaEncryptorRef = useRef(new JSEncrypt());

    useEffect(() => {
        BoxRequest.getServerPublicKey().then((publicKey: string | null) => {
            if (publicKey) {
                rsaEncryptorRef.current.setPublicKey(publicKey);
            }
        })
    }, [])


    const handleSubmit = () => {
        // 提交密码修改请求
        if (!oldPwd || !newPwd || !newPwdConfirm) {
            toast({
                title: 'Error',
                description: 'Please fill in all fields.',
            });
            return;
        }

        if (newPwd !== newPwdConfirm) {
            toast({
                title: 'Error',
                description: 'Passwords do not match.',
            });
            return;
        }
        let encryptedOldPwd: string = rsaEncryptorRef.current.encrypt(oldPwd) as string;
        let encryptedNewPwd: string = rsaEncryptorRef.current.encrypt(newPwd) as string;
        BoxRequest.post(BoxApi.changePassword, {
            oldPwd: encryptedOldPwd,
            newPwd: encryptedNewPwd,
        }).then((resp: BoxRespModel<null> | null) => {
            if (resp && resp.success) {
                toast({
                    title: 'Success',
                    description: 'Password updated successfully.',
                });
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                toast({
                    title: 'Failed to update password',
                    description: resp?.displayMsg,
                });
            }
        }).catch((err) => {
            toast({
                title: 'Error',
                description: 'An error occurred while updating password:' + err.message,
            });
        })
    }

    const checkCanSubmit = () => {

        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

        if (!oldPwd || !newPwd || !newPwdConfirm) {
            setCanSubmit(false);
            return;
        }
        if (newPwd != newPwdConfirm) {
            setCanSubmit(false);
            return;
        }
        if (!passwordRegex.test(newPwd)) {
            setCanSubmit(false);
            return;
        }

        setCanSubmit(true);
    }

    useEffect(() => {
        checkCanSubmit();
    }, [oldPwd, newPwd, newPwdConfirm])


    return (
        <div className="space-y-8">
            <div>
                <label>Old password</label>
                <Input className="focus-visible:ring-gray-400 focus-visible:ring-1"
                    placeholder="your old password"
                    value={oldPwd}
                    onChange={(e) => { setOldPwd(e.target.value) }} />
                <p className="text-sm text-muted-foreground">
                    This is the current password.
                </p>
                <label>New password</label>
                <Input className="focus-visible:ring-gray-400 focus-visible:ring-1"
                    placeholder="new password"
                    value={newPwd}
                    onChange={(e) => { setNewPwd(e.target.value) }} />
                <p className="text-sm text-muted-foreground">
                    This is the new password
                </p>
                <label>Confirm your password</label>
                <Input className="focus-visible:ring-gray-400 focus-visible:ring-1"
                    placeholder="Confirm your password"
                    value={newPwdConfirm}
                    onChange={(e) => { setNewPwdConfirm(e.target.value) }} />
                <p className="text-sm text-muted-foreground">
                    Confirm your password.
                </p>
            </div>
            <Button disabled={!canSubmit} onClick={() => { handleSubmit() }}>Update account</Button>
        </div>
    )
}