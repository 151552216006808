import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { TableHead, TableRow, TableHeader, TableCell, TableBody, Table } from "@/components/ui/table"
import { toast } from "@/components/ui/use-toast"
import BoxApi from "@/common/BoxApi"
import BoxRequest from "@/common/util/BoxRequest"
import { useNavigate, useParams } from "react-router-dom"
import { InboxInfoModel } from "@/common/model/InboxInfoModel"
import { useCallback, useEffect, useState } from "react"
import { BoxRespModel, PageRespModel } from "@/common/model/BoxRespModel"
import { InboxIdFileIdReqModel, PageQueryInboxFileReqModel, SortField } from "@/common/model/request/InboxReqModel"
import { InboxFileModel } from "@/common/model/response/InboxRespModel"
import { formatFileSize } from "@/common/util/FileUtil"
import CustomPagination from '@/components/CustomPagination'
import { BoxPageHeader } from "@/components/BoxHeaderFooter"
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card"
import { Eye as EyeIcon, Download as DownloadIcon, Trash as TrashIcon, Share as ShareIcon, SquarePen as SquarePenIcon, ChevronLeft as ChevronLeftIcon, Link as LinkIcon, Activity as ActivityIcon } from "lucide-react"

export default function Component() {

  const navigate = useNavigate();
  const { inboxId } = useParams();
  const defaultInboxInfo: InboxInfoModel = new InboxInfoModel();
  const [inboxInfo, setInboxInfo] = useState<InboxInfoModel>(defaultInboxInfo);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [fileList, setFileList] = useState<InboxFileModel[]>([]);
  const [deadlineTimeStr, setDeadlineTimeStr] = useState<string>('expired');


  const fetchInboxInfo = useCallback(async () => {
    if (!inboxId)
      return;
    try {
      const resp: BoxRespModel<InboxInfoModel> = await BoxRequest.post(BoxApi.getInboxInfo, { inboxId: inboxId });
      if (resp.success && null != resp.data) {
        setInboxInfo(resp.data);

        let expiryTime = new Date(resp.data.expiryTime);
        let isDeadlineTime: boolean = expiryTime < new Date();
        setDeadlineTimeStr(isDeadlineTime ? 'expired' : expiryTime.toLocaleString())


      } else {
        throw new Error(resp.displayMsg || 'Get inbox info fail.');
      }
    } catch (err) {
      toast({
        title: "Get inbox info fail.",
        description: `${err}`,
      });
      setTimeout(() => {
        navigate('/404');
      }, 2000);
    }
  }, [inboxId, navigate])

  const loadFileList = useCallback(async (pageNo: number, pageSize: number) => {
    if (!inboxId) {
      console.info('cannot load inbox files, inboxId is null.');
      return;
    }

    let reqModel = new PageQueryInboxFileReqModel();
    reqModel.pageNo = pageNo;
    reqModel.pageSize = pageSize;
    reqModel.inboxId = String(inboxId);
    reqModel.sortFields = [new SortField('ctime', 'desc')];

    try {
      const resp: BoxRespModel<PageRespModel<InboxFileModel>> = await BoxRequest.post(BoxApi.pageQueryInboxFile, reqModel);
      if (!resp.success || null == resp.data) {
        throw new Error(resp.displayMsg || 'Get inbox files fail.');
      }
      // 请求接口返回文件数据
      let pageResp: PageRespModel<InboxFileModel> = resp.data;
      setCurrentPage(pageResp.current);
      setTotalPages(pageResp.pages);
      setTotalCount(pageResp.total);
      if (0 === pageResp.size) {
        console.info(`inbox file list is empty, currentPage:${pageResp.current}, totalPages:${pageResp.pages}`);
        return;
      }
      setFileList(pageResp.records);

    } catch (err) {
      toast({
        title: "Get inbox files fail.",
        description: `${err}`,
      });
    }
  }, [inboxId])

  /**
   *  批量删除文件
   * @param inboxId 收件夹 id
   * @param fileIds 文件 id
   */
  const batchDeleteFiles = (inboxId: string, fileIds: string[]) => {
    let reqModel: InboxIdFileIdReqModel = new InboxIdFileIdReqModel();
    reqModel.setParams(inboxId, fileIds);

    BoxRequest.post(BoxApi.batchDeleteFile, reqModel)
      .then((resp: BoxRespModel<string>) => {
        if (resp.success) {
          toast({
            title: "Delete files success.",
            description: "Delete files success.",
          });
          loadFileList(currentPage, pageSize);
        } else {
          throw new Error(resp.displayMsg);
        }
      }).catch((err) => {
        toast({
          title: "Delete files fail.",
          description: `${err}`,
        });
      });
  }

  /**
   * 下载指定文件
   * @param fileId 文件id
   * @param operateType 操作类型: view:新页面打开查看, download: 直接下载二进制文件流
   */
  const downloadSpecificFile = (fileId: string, operateType: string) => {

    if (!operateType) {
      operateType = "view";
    }

    let reqModel: InboxIdFileIdReqModel = new InboxIdFileIdReqModel();
    reqModel.inboxId = String(inboxId);
    reqModel.fileIds = [fileId];

    BoxRequest.post(BoxApi.generateDownloadToken, reqModel)
      .then((resp: BoxRespModel<string>) => {
        if (resp.success && resp.data) {
          let downloadUrl = `${BoxApi.downloadSpecificFile}?operateType=${operateType}&inboxId=${inboxId}&fileId=${fileId}&token=${resp.data}`
          const newWindow = window.open(downloadUrl);

          if (null === newWindow) {
            throw new Error('Open download window fail, Please check your browser settings.');
          }
        } else {
          throw new Error(resp.displayMsg || 'Generate download token fail.');
        }
      }).catch((err) => {
        toast({
          title: "Failed to download file.",
          description: `${err}`,
        });
      });
  };

  const shareInboxByLink = () => {
    console.info(`shareInboxByLink clicked,inboxId:${inboxId}`)
    // 复制链接到剪贴板
    navigator.clipboard.writeText(`${window.location.origin}/inbox/${inboxId}/info`)
    toast({
      title: "Link Copied",
      description: "now you can share the link to others.",
    });
  }



  useEffect(() => {
    fetchInboxInfo();
  }, [inboxId, fetchInboxInfo])

  useEffect(() => {
    loadFileList(currentPage, pageSize);
  }, [currentPage, pageSize, loadFileList]);



  return (
    <div className="bg-white">
      <BoxPageHeader />
      <div className="px-6">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center space-x-4">
            <Button variant="ghost">
              <ChevronLeftIcon className="w-6 h-6" onClick={() => window.history.back()} />
            </Button>
            <h1 className="text-xl font-semibold">{inboxInfo.name}</h1>
          </div>
          <div className="flex space-x-2">
            <Button variant="ghost"
              onClick={() => {
                navigate(`/inbox/share?inboxId=${inboxId}`, { state: { inboxInfo: inboxInfo } });
              }} >
              <ShareIcon className="w-5 h-5 mr-2" />
              Share
            </Button>
            <Button variant="ghost">
              <SquarePenIcon className="w-5 h-5 mr-2" />
              Edit
            </Button>
            <Button variant="ghost" className="cursor-pointer" onClick={shareInboxByLink}>
              <LinkIcon className="w-5 h-5 mr-2" />
              Copy link
            </Button>
            <Button variant="ghost">
              <ActivityIcon className="w-5 h-5 mr-2" />
              submit logs
            </Button>
            {/* <Button variant="ghost">
            <PlusIcon className="w-5 h-5 mr-2" />
            create new one
          </Button> */}
          </div>
        </div>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                files
              </CardTitle>
              📄
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{inboxInfo.fileCount}</div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                submit users
              </CardTitle>
              👤
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{0}</div>
              {/* <p className="text-xs text-muted-foreground">
                      +180.1% from last month
                    </p> */}
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">notify email</CardTitle>
              ✉
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{inboxInfo.notifyEmail}</div>
              {/* <p className="text-xs text-muted-foreground">
                      +19% from last month
                    </p> */}
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Deadline time
              </CardTitle>
              📅
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{deadlineTimeStr}</div>
            </CardContent>
          </Card>
        </div>
        <div className="py-4">
          <div className="flex justify-between items-center">
            <Button className="mr-2" variant="outline">
              Search Files
            </Button>
            <div className="flex space-x-2">
              {/* <Button variant="outline">邀请提交文件</Button> */}
              <Button variant="outline">Export Excel</Button>
              {/* <Button variant="outline">查看所有文件</Button> */}
            </div>
          </div>
        </div>
        <div className="py-4">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[25%]">file name</TableHead>
                <TableHead className="w-[25%]">submit time</TableHead>
                <TableHead className="w-[10%]">type</TableHead>
                <TableHead className="w-[15%]">file size</TableHead>
                <TableHead className="w-[25%]">operate</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {fileList.map((file: InboxFileModel) => (
                <TableRow key={file.fileId}>
                  <TableCell> {file.fileName} </TableCell>
                  <TableCell>{(new Date(file.ctime)).toLocaleString()}</TableCell>
                  <TableCell>
                    <Badge variant="default">{file.fileType}</Badge>
                  </TableCell>
                  <TableCell>{formatFileSize(file.fileBytesSize)}</TableCell>
                  <TableCell className="flex space-x-2">
                    <EyeIcon className='cursor-pointer' onClick={() => { downloadSpecificFile(file.fileId, "view") }} />
                    <DownloadIcon className='cursor-pointer' onClick={() => downloadSpecificFile(file.fileId, "download")} />
                    <TrashIcon className='cursor-pointer' onClick={() => batchDeleteFiles(String(inboxId), [file.fileId])} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="mt-6 flex justify-end">
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalCount={totalCount}
            initPageSize={pageSize}
            onPageChange={(newPage, newPageSize) => { setCurrentPage(newPage); setPageSize(newPageSize) }} />
        </div>
      </div>
    </div>
  )
}
