import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter'


export default function Component() {

  return (
    <div className="flex flex-col min-h-screen justify-between">
      <BoxPageHeader />
      <h1>Privacy Policy</h1>
      <p>Last updated: [Date]</p>
      <p>This Privacy Policy describes how File Box ("us," "we," or "our") collects, uses, and shares information in
        connection with the "File Box" mobile application (the "Service"). By using the Service, you agree to the practices
        described in this Privacy Policy.</p>
      <p><strong>Information We Collect</strong></p>
      <ul>
        <li>
          <p><strong>Account Information:</strong> When you create an account on File Box, we collect information such as
            your name, email address, and other contact details.</p>
        </li>
        <li>
          <p><strong>Uploaded Content:</strong> File Box allows you to upload, store, and manage digital files. The
            content you upload to your file inbox is stored securely, and we do not access or use this content for any
            purpose other than to provide the Service.</p>
        </li>
        <li>
          <p><strong>Usage Information:</strong> We may collect information about how you interact with the Service,
            including log files, device and browser information, and usage patterns.</p>
        </li>
        <li>
          <p><strong>Cookies:</strong> We may use cookies and similar tracking technologies to improve your experience on
            the Service.</p>
        </li>
      </ul>
      <p><strong>How We Use Your Information</strong></p>
      <ul>
        <li>
          <p>To provide, maintain, and improve the Service.</p>
        </li>
        <li>
          <p>To respond to your requests and provide customer support.</p>
        </li>
        <li>
          <p>To send you important updates, notifications, and other information related to the Service.</p>
        </li>
        <li>
          <p>To analyze usage patterns and improve our Service.</p>
        </li>
      </ul>
      <p><strong>Information Sharing</strong></p>
      <p>We do not share your personal information with third parties, except in the following circumstances:</p>
      <ul>
        <li>
          <p>With your consent.</p>
        </li>
        <li>
          <p>When required by law or to protect our rights, privacy, safety, or property.</p>
        </li>
        <li>
          <p>In connection with the sale, merger, or acquisition of all or part of our company.</p>
        </li>
      </ul>
      <p><strong>Your Choices</strong></p>
      <p>You can control your personal information through your account settings. You can also opt out of certain
        communications, such as promotional emails.</p>
      <p><strong>Data Security</strong></p>
      <p>We take data security seriously and have implemented measures to protect your personal information. However, no data
        transmission over the internet can be guaranteed as 100% secure.</p>
      <p><strong>Children's Privacy</strong></p>
      <p>File Box is not intended for use by individuals under the age of 13. We do not knowingly collect information from
        children under 13. If you are a parent or guardian and believe we have collected information about a child, please
        contact us, and we will take steps to remove that information.</p>
      <p><strong>Changes to this Privacy Policy</strong></p>
      <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new
        Privacy Policy on this page.</p>
      <p><strong>Contact Us</strong></p>
      <p>If you have questions or concerns about this Privacy Policy, please contact us at [Your Contact Information].</p>
      <p>By using the "File Box" app, you acknowledge that you have read, understood, and agreed to this Privacy Policy.</p>

      <BoxPageFooter />
    </div>
  )
}